import gql from 'graphql-tag';

// export const metadataGql = gql`
//     fragment MetadataGql on Metadata {
//         title
//         description
//         image {
//             url
//         }
//     }
// `;

export const imageBannerFragmentGql = gql`
    fragment ImageBannerFragmentGql on ComponentModulesBannerImage {
        id
        ItemText
        ItemTitle
        showGoogleRating
        hideBreadcrumbs
        moduleType
        VideoUrl
        imageBannerImage: Image {
            url
        }
        Buttons {
            id
            manualLink
            btnClass
            label
            icon
            func
            page {
                Slug
                id
                Parent {
                    Slug
                }
                Parent2 {
                    Slug
                }
                Parent3 {
                    Slug
                }
            }
        }
    }
`

export const VideoBannerFragmentGql = gql`
    fragment VideoBannerFragmentGql on ComponentModulesBannerImage {
        id
        ItemText
        ItemTitle
        showGoogleRating
        hideBreadcrumbs
        moduleType
        VideoUrl
        imageBannerImage: Image {
            url
        }
        Buttons {
            id
            manualLink
            btnClass
            label
            icon
            func
            page {
                Slug
                id
                Parent {
                    Slug
                }
                Parent2 {
                    Slug
                }
                Parent3 {
                    Slug
                }
            }
        }
    }
`


export const LatestVideosFragmentGql = gql`
    fragment LatestVideosFragmentGql on ComponentModulesLatestVideos {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
        }
    }
`

export const FloatBannerFragmentGql = gql`
    fragment FloatBannerFragmentGql on ComponentModulesBannerImage {
        id
        ItemText
        ItemTitle
        showGoogleRating
        hideBreadcrumbs
        floatImage: Image {
            alternativeText
            url
        }
        Buttons {
            id
            manualLink
            btnClass
            label
            icon
            func
            page {
                Slug
                id
                Parent {
                    Slug
                }
                Parent2 {
                    Slug
                }
                Parent3 {
                    Slug
                }
            }
        }
    }
`
export const PropertySearchFragmentGql = gql`
    fragment PropertySearchFragmentGql on ComponentModulesSearchCta {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
    }
`

export const IconBoxFragmentGql = gql`
    fragment IconBoxFragmentGql on ComponentModulesIconBox {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
        Items {
            id
            Title
            Text
            icon
            btnManualLink
            btnClass
            btnLabel
            btnIcon
            btnPage {
                Slug
                id
                Parent {
                    Slug
                }
                Parent2 {
                    Slug
                }
                Parent3 {
                    Slug
                }
            }
        }
    }
`


export const PropertySliderFragmentGql = gql`
    fragment PropertySliderFragmentGql on ComponentModulesPropertySlider {
        id
        moduleType
        stb_lists {
            id
            Name
        }
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
    }
`

export const BoxCtaFragmentGql = gql`
    fragment BoxCtaFragmentGql on ComponentModulesBoxCta {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
        Button {
            id
            manualLink
            btnClass
            label
            icon
            func
            page {
                Slug
                id
                Parent {
                    Slug
                }
                Parent2 {
                    Slug
                }
                Parent3 {
                    Slug
                }
            }
        }
    }
`

export const AboutPeopleFragmentGql = gql`
    fragment AboutPeopleFragmentGql on ComponentModulesAboutPeople {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
        Button {
            id
            manualLink
            btnClass
            label
            icon
            page {
                Slug
                id
                Parent {
                    Slug
                }
                Parent2 {
                    Slug
                }
                Parent3 {
                    Slug
                }
            }
        }
    }
`

export const AwardsSliderFragmentGql = gql`
    fragment AwardsSliderFragmentGql on ComponentModulesAwards {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
    }
`

export const GoogleCtaFragmentGql = gql`
    fragment GoogleCtaFragmentGql on ComponentModulesGoogleCta {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
        }
        image {
            url
        }
        Button {
            id
            manualLink
            btnClass
            label
            icon
            func
            page {
                Slug
                id
                Parent {
                    Slug
                }
                Parent2 {
                    Slug
                }
                Parent3 {
                    Slug
                }
            }
        }
    }
`


export const LatestPodcastsFragmentGql = gql`
    fragment LatestPodcastsFragmentGql on ComponentModulesLatestPodcasts {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
    }
`

export const ContactMemberFragmentGql = gql`
    fragment ContactMemberFragmentGql on ComponentModulesContactMemberForm {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
        team {
            id
            JobTitle
            Name
            Phone
            Slug
            WhatsApp
            Image {
                alternativeText
                url
                
            }
            Email
        }
    }
`

export const ReviewsFragmentGql = gql`
    fragment ReviewsFragmentGql on ComponentModulesReviews {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
        }
        team {
            id
        }
    }
`


export const StatsFragmentGql = gql`
    fragment StatsFragmentGql on ComponentModulesStats {
        id
        moduleType
        rowItemsClass
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
        Items {
            id
            Text
            From
            To
            Suffix
            Prefix
            IconName
        }
        ListTitle
        ListItems {
            id
            Text
        }
    }
`


export const TeamStatsFragmentGql = gql`
    fragment TeamStatsFragmentGql on ComponentModulesTeamStats {
        id
        moduleType
        rowItemsClass
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
        Items {
            id
            Text
            From
            To
            Suffix
            Prefix
            IconName
            dynamicData
            Date
        }
    }
`


export const NormalTextFragmentGql = gql`
    fragment NormalTextFragmentGql on ComponentModulesNormalText {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
    }
`

export const ListAreasFragmentGql = gql`
    fragment ListAreasFragmentGql on ComponentModulesListAreas {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleId
            moduleClass
            sectionSpacing
        }
        team {
            id
            areas {
                Slug
                BannerImage {
                    ... on ComponentModulesBannerImage {
                        id
                        ItemTitle
                    }
                }
            }
        }
    }
`

export const ImgBtnFragmentGql = gql`
    fragment ImgBtnFragmentGql on ComponentModulesBannerImage {
        id
        ItemText
        ItemTitle
        showGoogleRating
        hideBreadcrumbs
        imgBtnImage: Image {
            alternativeText
            url
        }
        Button_Group_Text
        Buttons {
            id
            manualLink
            btnClass
            label
            icon
            func
            page {
                Slug
                id
                Parent {
                    Slug
                }
                Parent2 {
                    Slug
                }
                Parent3 {
                    Slug
                }
            }
        }
    }
`

export const IntroTextFragmentGql = gql`
    fragment IntroTextFragmentGql on ComponentModulesIntroText {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
    }
`


export const ImageGridFragmentGql = gql`
    fragment ImageGridFragmentGql on ComponentModulesImageGrid {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
        Images {
            Image {
                alternativeText
                url
            }
        }
    }
`

export const BookValuationFragmentGql = gql`
    fragment BookValuationFragmentGql on ComponentModulesBookValuation {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
        Buttons {
            id
            manualLink
            btnClass
            label
            icon
            func
            page {
                Slug
                id
                Parent {
                    Slug
                }
                Parent2 {
                    Slug
                }
                Parent3 {
                    Slug
                }
            }
        }
    }
`


export const TeamContactFragmentGql = gql`
    fragment TeamContactFragmentGql on ComponentModulesTeamContact {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
        team {
            id
            JobTitle
            Name
            Phone
            Slug
            WhatsApp
            Image {
                alternativeText
                url
            }
            Email
        }
    }
`

export const ManualTeamContactFragmentGql = gql`
    fragment ManualTeamContactFragmentGql on ComponentModulesManualTeamContact {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
        JobTitle
        Name
        Phone
        WhatsApp
        Email
        Image {
            alternativeText
            url
            
        }
    }
`

export const SocialShareFragmentGql = gql`
    fragment SocialShareFragmentGql on ComponentModulesSocialShare {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
    }
`
export const AllProjectsFragmentGql = gql`
    fragment AllProjectsFragmentGql on ComponentModulesAllProjects {
        id
        moduleType
        area_guide {
            id
        }
        moduleData {
            Text
            Title
            moduleId
            moduleClass
            sectionSpacing
        }
    }
`

export const GoogleMapsFragmentGql = gql`
    fragment GoogleMapsFragmentGql on ComponentModulesGoogleMaps {
        id
        moduleType
        MapLat
        MapLong
        Zoom
        moduleData {
            Text
            Title
            moduleClass
            moduleId
            sectionSpacing
        }
    }
`


export const SectionCtaFragmentGql = gql`
    fragment SectionCtaFragmentGql on ComponentModulesSectionCta {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
        Button {
            id
            manualLink
            btnClass
            label
            icon
            page {
                Slug
                id
                Parent {
                    Slug
                }
                Parent2 {
                    Slug
                }
                Parent3 {
                    Slug
                }
            }
        }
    }
`

export const ProjectImageBannerCarouselFragmentGql = gql`
    fragment ProjectImageBannerCarouselFragmentGql on ComponentModulesBannerCarousel {
        id
        Items {
            ItemText
            ItemTitle
            VideoUrl
            showGoogleRating
            GoogleReview_Content
            hideBreadcrumbs
            manualLink
            btnClass
            label
            icon
            page {
                Slug
                id
                Parent {
                    Slug
                }
                Parent2 {
                    Slug
                }
                Parent3 {
                    Slug
                }
            }
            projectImageBanner: Image {
                url
            }
        }
    }
`;
export const GET_ALL_REVIEWS = gql`
    query  GetReviews($propertyId: ID)  {
        reviews(publicationState: LIVE, sort: "Date:DESC", where: {
            Show_Vacation_Home_Review_Page: true
            Show_Multi_Country_Page: false
            property: { id: $propertyId }
          }) {
            id
            Text
            Person
            Date
            property {
                id
            }
        }
    }
`;