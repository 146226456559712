import * as React from "react";

function HtmlText(props) {
    const modifiedHTML = typeof window !== 'undefined' ? modifyLinks(props.html) : props.html;
    return <span dangerouslySetInnerHTML={createHTMLMarkup(modifiedHTML)} className={props?.className} />;
}

function createHTMLMarkup(html) {
    return { __html: html };
}

function modifyLinks(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const links = doc.querySelectorAll('a');
    if (typeof window === 'undefined') {
        return html;
    }
    links.forEach(link => {
        const href = link.getAttribute('href');
        if (href) {
            if (href.startsWith('http') || href.startsWith('//')) {
                const url = new URL(href, window.location.origin);

                if (url.hostname !== window.location.hostname) {
                    link.setAttribute('target', '_blank');
                } else {
                    link.removeAttribute('target');
                }
            } else {
                link.removeAttribute('target');
            }
        }
    });
    return doc.body.innerHTML;
}

const ModuleTitle = (props) => {
    const {
        className,
        text,
        as,
    } = props
    const As = as ? as : "div";

    return(
        <As className={className}>
            <HtmlText html={text} />
        </As>
    )
}

export default ModuleTitle