import React from "react";
import { GET_ALL_REVIEWS } from "@components/gql";
import { useQuery } from '@apollo/client';
import Routes from '@components/routes';
import Link from '@components/ButtonLink';
import { StarberryIcons } from "@components/icons/index";
import "./style.scss";

const scrollToPropertyReviewsList = () => {
    const propertyReviewsList = document.getElementById('property-reviews-list');
    if (propertyReviewsList) {
      propertyReviewsList.scrollIntoView({behavior: 'smooth'});
    }
};
const renderReviewContent = ( reviewText, showStars) => (
    <span className={`${showStars ? "review-text" : ""}`}>
      {reviewText}
      {showStars && <StarberryIcons iconName="FiveStarIcon" className="five-star" /> }
    </span>
);
const TotalReviewsCount = ({ propertyId, department, linkReviews, showStars }) => {
   const { loading, error, data} = useQuery(GET_ALL_REVIEWS, {
        variables: { propertyId },
    });
   const totalCount = data?.reviews?.length ?? 0;
   const reviewCountText = totalCount > 1 ? ' Reviews' : ' Review';
   return (
    <div className="reviews-count">
        {totalCount !== undefined && totalCount > 0 && !error && ( 
            (propertyId && department === "short_term" && linkReviews ? 
                <Link variant="none" onClick={scrollToPropertyReviewsList} className="reviews-link">  {renderReviewContent( `${totalCount}${reviewCountText}`, showStars)}</Link> 
            :
                (!propertyId && (department === "short_term" || department ==="vacationHomes") && linkReviews) ? <Link variant="none" manualLink={`${Routes.VacationHomesCustomerReviews}`} className="reviews-link"> {renderReviewContent(`${totalCount}${reviewCountText}`, showStars)}</Link>  
            : renderReviewContent(`${totalCount}${reviewCountText}`, showStars)
            )
            
        )}
    </div>
)};
export default TotalReviewsCount;